import menuPermissions from './menuPermissions';

const validMenu = (originalMenu, userLevel) => {
  const MenuPermissions = menuPermissions();

  let menu = {
    icon: originalMenu.icon,
    label: originalMenu.label,
    content: [],
    to: ''
  }

  if (!originalMenu.to) {
    menu.content = originalMenu.content.map(subMenu => {
      return validMenu(subMenu, userLevel);
    });
  } else {
    if (!MenuPermissions[originalMenu.to] || MenuPermissions[originalMenu.to].includes(parseInt(userLevel))) {
      menu.to = originalMenu.to;
    }
  }

  menu.content = menu.content.filter(subMenu => {
    return (subMenu.content.length > 0 || subMenu.to);
  });

  return menu;
}

export default validMenu;
