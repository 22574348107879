import api from "./api";

export const getInfoUser = () => JSON.parse(localStorage.getItem('@userInfo'));

export const getOrganizationTaxes = () => JSON.parse(localStorage.getItem('@organizationTaxes'));

export const logout = async () => {
  let user = getInfoUser();

  if (user && window.EchoAdmin) {
    window.EchoAdmin.leave(`user_session.${user.id}`);
  }

  await api.get('logout');

  localStorage.removeItem('@userInfo');
  window.location.reload();
};
