import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoading } from "../../../reducers/AppConfig";

import { RiEyeCloseLine } from 'react-icons/ri';
import { ImEye } from 'react-icons/im'
import { Col, Row, Button, FormGroup, Label, Input, InputGroupAddon, InputGroup, InputGroupText, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../Layout/AppMain/Components/Toast";

import api from "../../../services/api";
import { formatPassword, dateNow, isMobile } from 'nfutils';
import PasswordForce from "../../../Layout/AppMain/Components/PasswordForce";
// Layout

const RecoveryPassword = ({ setLoading, match, history, isAuthenticated }) => {
    const { user_id, token } = match.params
    const [levelPassword, setLevelPassword] = useState(0);
    const [showPasswordForce, setShowPasswordForce] = useState(false);
    const [ state, setState ] = useState({
        password: '',
        confirm_password: ''
    })
    const [ validation, setValidation ] = useState({})
    const [ viewPassword, setViewPassword ] = useState(false)
    const [ viewConfirmPassword, setViewConfirmPassword ] = useState(false)

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    const handleChange = (value, prop) => {
        setState({ ...state, [prop]: formatPassword(value) })
    }

    const handleValidate = input => {
        let invalid = {}
        if (!!input) {
            invalid = {...validation}
            invalid[input] = false
        }

        if ((!state.password || !state.password.trim() || levelPassword < 6) && (!input || input === 'password')) {
            invalid.password = true
        }
        if (state.confirm_password !== state.password && (!input || input === 'confirm_password')) {
            invalid.confirm_password = true
        }

        setValidation(invalid)

        if (Object.keys(invalid).length > 0 && !input) {
            ErrorToast({ placeholder: 'Os campos marcados com * são obrigatórios' })
            return false
        }

        return true
    }

    const handleFormData = () => {
        return {
            ...state,
            user_id,
            token
        }
    }

    const handleSubmit = async () => {
        if (!handleValidate()) {
            return
        }

        try {
            setLoading(true, 'Alterando a senha...')
            await api.post('forgot/recovery', handleFormData())
            SuccessToast({ placeholder: 'Senha alterada com sucesso!' })
            history.push('/login')
        } catch (e) {
            console.log(e)
            let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar solicitar a redifinição de senha, tente novamente mais tarde'
            ErrorToast({ placeholder: msg })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        } else {
            setLoading(true, 'Consultando token...')
            api.post('forgot/valid', { user_id, token })
            .then(_ => setLoading(false))
            .catch(e => {
                console.log(e)
                setLoading(false)
                let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar validar o link, tente novamente mais tarde'
                ErrorToast({ placeholder: msg })
                history.push('/login')
            })
        }
    }, [isAuthenticated, user_id, token])

    return (
        <>
          <div className={`h-100 bg-plum-plate bg-animation`}>
            <div className={`d-flex h-100 justify-content-center align-items-center`}>
              <Col md="8" className="mx-auto app-login-box">
                <div className="app-logo-inverse mx-auto mb-3" style={{ height: '8rem' }} />
                <div className="modal-dialog w-100 mx-auto">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                          <div>Bem vindo,</div>
                          <span>Utilize os campos abaixo para redefinir sua senha.</span>
                        </h4>
                      </div>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label><span className="text-danger">*</span> Nova Senha</Label>
                                    <InputGroup>
                                        <Input invalid={validation.password} type={viewPassword ? 'text' : 'password'} id="password" placeholder="Digite sua senha..."  value={state.password} onChange={({ target: {value} }) => handleChange(value, 'password')} onFocus={() => setShowPasswordForce(true)} onBlur={() => {handleValidate('password'); setShowPasswordForce(false)}} autoComplete="new-password" onKeyUp={handleKeyUp} />
                                        <InputGroupAddon id="view_password" style={{cursor: 'pointer'}} addonType="append" onClick={() => setViewPassword(!viewPassword)}>
                                            <InputGroupText>
                                                {viewPassword ? <ImEye /> : <RiEyeCloseLine />}
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <FormFeedback>Informe uma nova senha válida</FormFeedback>
                                    </InputGroup>
                                    {
                                    showPasswordForce &&
                                    <>
                                        <br />
                                        <PasswordForce password={state.password} onLevel={value => setLevelPassword(value)} />
                                    </>
                                    }
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Confirme a Senha</Label>
                                    <InputGroup>
                                        <Input invalid={validation.confirm_password} type={viewConfirmPassword ? 'text' : 'password'} id="confirm_password" placeholder="Confirme a senha digitada..."  value={state.confirm_password} onChange={({ target: {value} }) => handleChange(value, 'confirm_password')} onBlur={() => handleValidate('confirm_password')} autoComplete="new-password" onKeyUp={handleKeyUp} />
                                        <InputGroupAddon id="view_confirm_password" style={{cursor: 'pointer'}} addonType="append" onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
                                            <InputGroupText>
                                                {viewConfirmPassword ? <ImEye /> : <RiEyeCloseLine />}
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <FormFeedback>As senhas não coincidem</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                      <div className="divider" />
                      <h6 className="mt-3">
                        <Link id="login" to="/login">
                            Entre com uma conta existente
                        </Link>
                      </h6>
                      <div className="d-flex align-items-center">
                            <div className="ml-auto">
                                <Button type="button" color="primary" size="lg" onClick={handleSubmit}>
                                    Alterar Senha
                                </Button>
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center text-white opacity-8 mt-3">
                  Copyright &copy; WFPay Gestão Financeira {dateNow(null, 'YYYY')}
                </div>
              </Col>
            </div>
          </div>
        </>
      );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.AppConfig.isAuthenticated,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);
