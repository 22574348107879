export const MenuNav = [
  {
    icon: "pe-7s-graph3",
    label: "Resumo",
    to: "/#/",
  },
  {
    icon: "pe-7s-users",
    label: "Clientes",
    to: "/#/clientes",
  },
  {
    icon: "pe-7s-id",
    label: "Documentos",
    to: "/#/documentos",
  },
  {
    icon: "pe-7s-bottom-arrow",
    label: "Transferências",
    to: "/#/transferencias",
  },
  {
    icon: "bi bi-upc",
    label: "Pagamentos",
    to: "/#/pagamentos",
  },
  {
    icon: "pe-7s-attention",
    label: "Motivos de Rejeição",
    to: "/#/rejeicoes",
  },
  {
    icon: "pe-7s-credit",
    label: "Recusas de Cartões",
    to: "/#/recusa-cartoes",
  },
  {
    icon: "bi bi-file-earmark-text",
    label: "Extratos",
    to: "/#/extratos",
  },
  {
    icon: "bi bi-search",
    label: "Rastrear Origem Cobrança",
    to: "/#/origem-cobranca",
  },
  {
    icon: "pe-7s-display1",
    label: "Relatórios",
    content: [
      {
        label: "Totais de contas recebidas",
        to: "/#/relatorios/totais-de-contas-recebidas",
      },
      {
        label: "Clientes",
        to: "/#/relatorios/clientes",
      },
      {
        label: "Lançamentos",
        to: "/#/relatorios/lancamentos",
      },
      {
        label: "Totais de custos de serviços",
        to: "/#/relatorios/totais-custos-servicos",
      },
      {
        label: "Rentabilidade",
        to: "/#/relatorios/rentabilidade",
      },
    ],
  },
  {
    icon: "bi bi-hand-index",
    label: "Ações manuais",
    content: [
      {
        label: "Confirmar pagamentos",
        to: "/#/acoes-manuais/confirmar-pagamentos",
      }
    ],
  },
];

const getRoutes = (menu) => {
  let routes = {}
  for (let i in menu) {
    if (menu[i].to) {
      routes[menu[i].to] = menu[i].to;
    }
    if(menu[i].content && menu[i].content.length > 0) {
      routes = {
        ...routes,
        ...getRoutes(menu[i].content)
      }
    }
  }

  return routes
}

export const ListRoutes = () => getRoutes(MenuNav);
