import React, { Suspense, lazy, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";

import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import Loader from "react-loaders";

import menuPermissions from "../../services/menuPermissions";
import { ErrorToast } from "./Components/Toast";
import { ListRoutes } from "../AppNav/NavItems";
import { setActiveLinkTo } from "../../reducers/AppConfig";

import '../../assets/layout/css/style.css';

const Dashboard = lazy(() => import("../../Pages/Dashboard"));
const Users =  lazy(() =>  import("../../Pages/User"));
const Profile =  lazy(() =>  import("../../Pages/Profile"));
const Document =  lazy(() =>  import("../../Pages/Document"));
const Log = lazy(() => import("../../Pages/Log"));
const Payment = lazy(() => import("../../Pages/Payment"));
const Transfer = lazy(() => import("../../Pages/Transfer"));
const RejectionReason = lazy(() => import("../../Pages/RejectionReason"));
const Extract = lazy(() => import("../../Pages/Extract"));
const TotalReceived = lazy(() => import("../../Pages/TotalReceived"));
const CustomersReport = lazy(() => import("../../Pages/CustomersReport"));
const Customers = lazy(() => import("../../Pages/Customers"));
const ConfirmPayment = lazy(() => import("../../Pages/ConfirmPayment"));
const Transactions = lazy(() => import("../../Pages/Transactions"));
const TotalServiceCosts = lazy(() => import("../../Pages/TotalServiceCosts"));
const Profitability = lazy(() => import("../../Pages/Profitability"));
const Setting = lazy(() => import("../../Pages/Setting"));
const ChargeOrigin = lazy(() => import("../../Pages/ChargeOrigin"));
const ChargeCardRefuses = lazy(() => import("../../Pages/ChargeCardRefuses"));

const AppMain = (props) => {
  const accessValidate = (route, user) => {
    let routeAdmin = ['/logs', '/usuarios', '/usuarios/cadastrar']

    if (user.level === 1) {
      return true;
    } else if (routeAdmin.includes(route)) {
      return false;
    } else if (!menuPermissions()[`/#${route}`]) {
      return true;
    } else if (menuPermissions()[`/#${route}`].includes(user.level)) {
      return true;
    }

    return false;
  }

  const verifyActiveLink = () => {
    let listRoutes = ListRoutes();
    if (!!listRoutes[`/#${props.history.location.pathname}`]) {
      setTimeout(() => props.setActiveLinkTo(`/#${props.history.location.pathname}`), 100);
    } else {
      let hasLink = false
      for(let i in listRoutes) {
        if (`/#${props.history.location.pathname}`.indexOf(i) !== -1 && i !== '/#/') {
          hasLink = true
          setTimeout(() => props.setActiveLinkTo(i), 100);
        }
      }

      if (!hasLink) {
        setTimeout(() => props.setActiveLinkTo(`/#${props.history.location.pathname}`), 100);
      }
    }
  }

  useEffect(() => {
    verifyActiveLink();
    props.history.listen((location) => {
      verifyActiveLink();
      if (!accessValidate(location.pathname, props.user)) {
        ErrorToast({ placeholder: 'Você não possui permissão de acesso à essa página! Caso necessite, entre em contato com o administrador do sistema!' })
        props.history.push('/');
      }
    });
  }, []);

  return (
    <>
      <Suspense fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader show={true} type="ball-spin-fade-loader"/>
            </div>
            <h6 className="mt-5">
              Carregando...
            </h6>
          </div>
        </div>
      }>
        <AppHeader {...props} />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Switch>
                  {/** Aqui deve ser definidas as rotas do sistema */}
                  <Route exact path="/" render={({match}) => <Dashboard {...props} match={match} />
                  } />
                  <Route path="/usuarios" render={({match}) => <Users {...props} match={match} />
                  } />
                  <Route path="/perfil" render={({match}) => <Profile {...props} match={match} />
                  } />
                  <Route path="/logs" render={({match}) => <Log {...props} match={match} />
                  } />
                  <Route path="/clientes" render={({match}) => <Customers {...props} match={match} />
                  } />
                  <Route path="/documentos" render={({match}) => <Document {...props} match={match} />
                  } />
                  <Route path="/pagamentos" render={({match}) => <Payment {...props} match={match} />
                  } />
                  <Route path="/transferencias" render={({match}) => <Transfer {...props} match={match} />
                  } />
                  <Route path="/rejeicoes" render={({match}) => <RejectionReason {...props} match={match} />
                  } />
                  <Route path="/extratos" render={({match}) => <Extract {...props} match={match} />
                  } />
                  <Route path="/acoes-manuais/confirmar-pagamentos" render={({match}) => <ConfirmPayment {...props} match={match} />
                  } />
                  <Route path="/relatorios/totais-de-contas-recebidas" render={({match}) => <TotalReceived {...props} match={match} />
                  } />
                  <Route path="/relatorios/clientes" render={({match}) => <CustomersReport {...props} match={match} />
                  } />
                  <Route path="/relatorios/lancamentos" render={({match}) => <Transactions {...props} match={match} />
                  } />
                  <Route path="/relatorios/totais-custos-servicos" render={({match}) => <TotalServiceCosts {...props} match={match} />
                  } />
                  <Route path="/relatorios/rentabilidade" render={({match}) => <Profitability {...props} match={match} />
                  } />
                  <Route path="/configuracoes" render={({match}) => <Setting {...props} match={match} />
                  } />
                  <Route path="/origem-cobranca" render={({match}) => <ChargeOrigin {...props} match={match} />
                  } />
                  <Route path="/recusa-cartoes" render={({match}) => <ChargeCardRefuses {...props} match={match} />
                  } />
                  <Route render={() => <Redirect to="/" />} />
              </Switch>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  )
};

const mapStateToProps = (state) => ({
  user: state.AppConfig.user
})

const mapDispatchToProps = (dispatch) => ({
  setActiveLinkTo: (link) => dispatch(setActiveLinkTo(link))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);
