import React, { Fragment, useEffect, useState } from "react";
import MetisMenu from "react-metismenu";
import validMenu from "../../services/validMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  MenuNav
} from "./NavItems";

const VerticalNavWrapper = ({ enableMobileMenu, setEnableMobileMenu, activeLinkTo, user }) => {
  const [state, setState] = useState({
    menu: []
  });

  const toggleMobileSidebar = () => {
    setEnableMobileMenu(enableMobileMenu);
  };

  useEffect(() => {
    const loadMenu = async () => {
      let menu = [];
      MenuNav.forEach(m => {
        let menuValidate = validMenu(m, user.level);
        if (menuValidate.content.length > 0 || menuValidate.to) {
          menu.push(menuValidate)
        }
      })

      setState({ ...state, menu } );
    }

    return loadMenu();
  }, [user.level])

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">Menu</h5>
      <MetisMenu content={state.menu} onSelected={toggleMobileSidebar} activeLinkTo={activeLinkTo}
      className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  activeLinkTo: state.AppConfig.activeLinkTo,
  user: state.AppConfig.user
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerticalNavWrapper));
