const menuPermissions = () => {
  return {
    '/#/transferencias': [1, 2],
    '/#/pagamentos': [1, 2],
    '/#/configuracoes': [1]
  }
}

export default menuPermissions;

