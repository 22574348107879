import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Nav from "../AppNav/VerticalNavWrapper";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderLogo from "../AppLogo";

import { setEnableMobileMenu } from "../../reducers/ThemeOptions";

const AppSidebar = props => {
  let {
    backgroundColor,
    enableBackgroundImage,
    enableSidebarShadow,
    backgroundImage,
    backgroundImageOpacity,
  } = props;

  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar}/>
      <TransitionGroup component="div" appear={true} enter={false} exit={false} className={cx("app-sidebar", backgroundColor, {
          "sidebar-shadow": enableSidebarShadow,
        })}>
          <CSSTransition timeout={800} classNames="SidebarAnimation">
            <HeaderLogo />
          </CSSTransition>
          <CSSTransition timeout={800} classNames="SidebarAnimation">
            <PerfectScrollbar>
              <div className="app-sidebar__inner">
                <Nav />
              </div>
            </PerfectScrollbar>
          </CSSTransition>
          <CSSTransition timeout={800} classNames="SidebarAnimation">
            <div className={cx("app-sidebar-bg", backgroundImageOpacity)}
              style={{
                backgroundImage: enableBackgroundImage
                  ? "url(" + backgroundImage + ")"
                  : null,
              }}>
            </div>
          </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
