import React, { Fragment, useState } from "react";
import { connect } from 'react-redux'
import { setLoading } from '../../../reducers/AppConfig'

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { FaRegUser } from "react-icons/fa";

import { getInfoUser, logout } from "../../../services/auth";
import { setEnableMobileMenuSmall } from "../../../reducers/ThemeOptions";

const UserBox = ({ setLoading, enableMobileMenuSmall, setEnableMobileMenuSmall }) => {
  const user = getInfoUser();
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const handleToggleDropDown = () => {
    setToggleDropDown(!toggleDropDown);
  }

  const handleToggleMobileSmall = () => {
    setEnableMobileMenuSmall(!enableMobileMenuSmall)
  }

  const handleToggle = () => {
    handleToggleDropDown()
    handleToggleMobileSmall()
  }

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left ml-3 header-user-info">
              <div className="widget-heading">{user.name}</div>
              <div className="widget-subheading">{user.email}</div>
            </div>
            <div className="widget-content-left">
              <UncontrolledButtonDropdown id="btnUserBox" toggle={handleToggleDropDown} isOpen={toggleDropDown}>
                <DropdownToggle onClick={handleToggleDropDown} className="p-0 ml-2 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle" style={{height: 44, width: 44}}>
                    <div className="icon-wrapper-bg bg-primary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                    <FaRegUser style={{margin: "0 auto"}} color="var(--primary)" fontSize="20px" />
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-primary">
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {user.organization !== undefined && <>{user.organization.trade || user.organization.name}</>}
                              </div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button id="logout" className="btn-pill btn-shadow btn-shine" color="dark" onClick={async () => {
                                setLoading(true);
                                await logout();
                                setLoading(false);
                              }}>
                                Sair
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-area-xs"
                    style={{
                      height: "auto",
                    }}>
                    <PerfectScrollbar>
                      <Nav onClick={handleToggle} vertical>
                        <NavItem>
                          <NavLink href="/#/perfil">
                            Meus Dados
                          </NavLink>
                        </NavItem>
                        {
                          user.level === 1 &&
                          <>
                            <NavItem>
                              <NavLink href="/#/usuarios">
                                Meus Usuários
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink href="/#/configuracoes">
                                Configurações
                              </NavLink>
                            </NavItem>
                          </>
                        }
                        </Nav>
                    </PerfectScrollbar>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  loading: state.AppConfig.loading,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text)),
  setEnableMobileMenuSmall: (enable) => dispatch(setEnableMobileMenuSmall(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
