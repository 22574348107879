import React from 'react'
import { connect } from "react-redux";
import { setLoading, setIsBlocked } from '../../reducers/AppConfig';
import { Route, Redirect } from "react-router-dom"

import LoadingOverlay from "react-loading-overlay";
import { FormGroup, Label, Row, Col, Button } from "reactstrap";
import { logout } from "../../services/auth";

const PrivateRoute = ({ component: Component, isBlocked, setIsBlocked, useHere, width, notifications, isAuthenticated, setLoading, ...rest }) =>  {
  LoadingOverlay.propTypes = undefined;

  return (
    <LoadingOverlay tag="div" active={isBlocked}
    styles={{
      overlay: (base) => ({
        ...base,
        background: "#f1f4f6",
        color: "#000",
        position: 'fixed',
        zIndex: '99999999999999'
      }),
    }}
    spinner={false}
    text={<Row style={{textAlign: 'right'}}>
      <Col md={12}>
        <FormGroup>
          <Label>Acesso ativo em outra janela, clique em "Usar aqui" para utilizar-lo nesta janela ou clique em "Desconectar" para voltar a página de login</Label>
        </FormGroup>
      </Col>
      <Col md={12}>
        <Button style={{margin: '10px', fontSize: '15px'}} onClick={async () => {
          setIsBlocked(false);
          setLoading(true);
          await logout();
          setLoading(false);
        }} color="danger" outline={true} >Desconectar</Button>
        <Button style={{margin: '10px', fontSize: '15px'}} onClick={useHere} color="primary" >Usar aqui</Button>
      </Col>
    </Row>}
    >
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Component
              {...props}
              width={width}
              notifications={notifications}
            />
          ) : (
            isAuthenticated !== null &&
            <Redirect to="/login" />
          )
        }
      />
    </LoadingOverlay>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.AppConfig.isAuthenticated,
  isBlocked: state.AppConfig.isBlocked,
})

const mapDispatchToProps = (dispatch) => ({
  setLoading: (enable, text = null) => dispatch(setLoading(enable, text)),
  setIsBlocked: (enable) => dispatch(setIsBlocked(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
